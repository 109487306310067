$color-text-primary: #333;
$color-background-button: #000;
$color-background-button-hover: #0056b3;
$color-border: #e1e1e1;
$color-placeholder: #aaa;
$spacing-content: 2rem;
$spacing-section: 1rem;
$font-size-large: clamp(1.5rem, 5vw, 2rem);
$font-size-base: 1rem;
$border-radius: 0.5rem;
