@import '../../styles/variables';

.portfolio-highlights {
  /* Occupies full viewport height; enable horizontal scrolling only */
  height: 120vh;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  background-color: #000; // adjust as desired
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch; // smooth scrolling on iOS

  /* This ensures that snapping respects a left-side offset */
  scroll-padding-left: 5vw;

  &__section-title {
    position: absolute;
    top: 0rem;       /* Increased from 2rem for better vertical positioning */
    left: 5vw;       /* Align with the scroll-padding */
    z-index: 3;
    color: #fff;
    font-size: 4rem; /* Increased font size to match Apple's style */
    font-weight:bold;
    //text-transform: uppercase;  /* Matches the feel of Apple's title */
    letter-spacing: 0.0em;
  }

  &__container {
    display: flex;
    height: 100%;
    /* Remove left padding here to avoid conflicts with scroll-padding */
    gap: 1vw;
    padding-left: 5vw; /* Ensure the first item has extra separation */
    align-items: flex-end;
  }

  &__item {
    position: relative;
    /* Each item occupies slightly less than the full viewport width for separation */
    flex: 0 0 calc(100vw - 15vw); /* Adjusted to account for container padding and desired margins */
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Create the “squircle” look with rounded edges */
    border-radius: 2rem;
    display: block;
  }

  &__item-title {
    position: absolute;
    top: 2rem;
    left: 2rem;
    color: #fff;
    font-size: 2.5rem;
    font-weight: normal;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
    margin: 0;
    z-index: 2;
  }
}