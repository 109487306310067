$color-text-primary: #333;
$color-background-button: #000;
$color-background-button-hover: #0056b3;
$color-border: #e1e1e1;
$color-placeholder: #aaa;
$spacing-content: 2rem;
$spacing-section: 1rem;
$font-size-large: clamp(1.5rem, 5vw, 2rem);
$font-size-base: 1rem;
$border-radius: 0.5rem;

.main-page {
  font-family: 'Arial', sans-serif; 

  &__content {
    display: flex; 
    align-items: stretch; 
    margin: $spacing-content;
    gap: 5rem;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__text-content {
    flex: 1; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 768px) {
      order: 2; 
    }
  }

  &__title {
    margin: 0 0 $spacing-section 0;
    font-size: $font-size-large;
    color: $color-text-primary;
  }

  &__contact-button {
    align-items: flex-end; 
    margin-top: auto;
    padding: $spacing-content;
    border: none;
    background-color: $color-background-button;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: $font-size-base;
    border-radius: $border-radius;

    &:hover,
    &:focus {
      background-color: $color-background-button-hover;
      outline: none;
    }
  }

  &__image-container {
    flex: 1; 
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-border;
    border-radius: $border-radius;
    @media (max-width: 768px) {
      order: 1; 
      min-height: 200px;
    }
  }

  &__image-description {
    color: $color-placeholder;
    text-align: center;
    font-size: $font-size-base;
  }
}

.single-page {
    display:flex;
    height:80vh;
    padding-bottom: 5rem;
}
