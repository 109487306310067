@import '../../styles/variables';

.header {
  background-color: #fff;
  padding: 1rem;
  border-bottom: 1px solid $color-border;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center; // Ensures content remains centered
  transition: transform 0.3s ease-in-out;

  &--hidden {
    transform: translateY(-100%);
  }

  &__nav {
    display: flex;
    justify-content: space-between; // Pushes logo left and links right
    align-items: center;
    width: 100%;
    max-width: 1200px; // Ensures it doesn't stretch too much
    padding: 0 2rem;
  }

  &__nav-left {
    display: flex;
    align-items: center;
    margin-left: -1rem;
  }

  &__nav-logo {
    display: flex;
    align-items: center;

    img {
      height: 50px; // Adjust size as needed
      width: auto;
      object-fit: contain;
      margin-top: 0px; // Moves the logo slightly downward
    }
  }

  &__nav-right {
    display: flex;
    align-items: center;
    gap: 2rem; // Space between the links
  }

  &__nav-link {
    text-decoration: none;
    color: $color-text-primary;
    font-size: $font-size-base;
    transition: color 0.3s ease;

    &:hover,
    &:focus {
      color: $color-background-button-hover;
    }
  }
}