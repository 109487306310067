@import '../../styles/variables';

.transition-page {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #000; /* Black background */
  overflow: hidden;

  .canvas {
    width: 100%;
    height: 100%;
    display: block;
  }

  .transition-message {
    color: #fff;
    font-size: 2rem;
    text-align: center;
    max-width: 1200px;
    width: 55vw;
    padding: 1rem;
    margin: 0 auto;
  }
}