@import '../../styles/variables';

.services {
  position: relative;
  padding: 3rem 2rem 2rem;  // Top padding leaves space for the title
  background-color: #000;
  
  /* The section title is positioned top left */
  &__title {
    position: absolute;
    top: 1rem;
    left: 5vw; 
    font-size: 4rem;
    font-weight: bold;
    color: #fff;
    margin: 0;
  }

  &__list {
    margin-top: 4rem;  // Leave room for the absolutely positioned title
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
}

.service-card {
  width: 100%;
  display: flex; // Added flex container
  flex-direction: column; // Stack elements vertically
  align-items: center; // Center items horizontally if needed

  &__title {
    font-size: 2.5rem;
    font-weight: normal;
    color: #fff;
    text-align: center;
    margin-bottom: 1rem; // Space between title and image
  }

  &__image {
    position: relative;
    width: 100%;
    
    img {
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
  
  &__technologies {
    list-style: none;
    padding: 0;
    margin: 1rem 0 0;
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    
    &__technology,
    .service-card__technology {
      background-color: #eee;
      padding: 0.5rem 1rem;
      border-radius: 20px;
      font-size: 1rem;
      color: #333;
    }
  }
}